import React from "react"

import { SectionHeading, TextBlock, LinkButton } from "../../UI/Common"

import { textPanelWrapperCls, textPanelCls } from "./TextPanel.module.scss"

const TextPanel = ({ sectionHeading, text, button }) => {
  return (
    <div className={textPanelWrapperCls}>
      <div className={textPanelCls}>
        <SectionHeading alignment="center" text={sectionHeading} mode="dark" />
        <TextBlock className="mb-5" text={text} mode="dark" alignment="center" />
        {button && <LinkButton
          link={button?.url}
          title={button?.title}
          target={button?.target}
        />}
      </div>
    </div>
  )
}

export default TextPanel
