// extracted by mini-css-extract-plugin
export var bgGrey100 = "TextPanel-module--bg-grey-100--490b2";
export var bgGrey150 = "TextPanel-module--bg-grey-150--df742";
export var bgGrey200 = "TextPanel-module--bg-grey-200--b129b";
export var bgGrey300 = "TextPanel-module--bg-grey-300--db01d";
export var bgGrey400 = "TextPanel-module--bg-grey-400--8c9dc";
export var bgGrey500 = "TextPanel-module--bg-grey-500--9b083";
export var bgGrey600 = "TextPanel-module--bg-grey-600--017cb";
export var bgGrey700 = "TextPanel-module--bg-grey-700--f38f5";
export var bgGrey800 = "TextPanel-module--bg-grey-800--aff27";
export var bgGrey900 = "TextPanel-module--bg-grey-900--2bfbc";
export var textGrey100 = "TextPanel-module--text-grey-100--922d8";
export var textGrey150 = "TextPanel-module--text-grey-150--e22a4";
export var textGrey200 = "TextPanel-module--text-grey-200--ca533";
export var textGrey300 = "TextPanel-module--text-grey-300--9fff2";
export var textGrey400 = "TextPanel-module--text-grey-400--f320d";
export var textGrey500 = "TextPanel-module--text-grey-500--f5596";
export var textGrey600 = "TextPanel-module--text-grey-600--e9e69";
export var textGrey700 = "TextPanel-module--text-grey-700--9cd10";
export var textGrey800 = "TextPanel-module--text-grey-800--e99c5";
export var textGrey900 = "TextPanel-module--text-grey-900--9431b";
export var textPanelCls = "TextPanel-module--textPanelCls--6f4ca";
export var textPanelWrapperCls = "TextPanel-module--textPanelWrapperCls--89bf0";