import { graphql } from "gatsby"
import React from "react"

import TextPanelWithTwoBkgdImgs from "./TextPanelWithTwoBkgdImgs"

export const fragment = graphql`
  fragment TextPanelWithTwoBkgdImgsFragment on WpPage_Flexlayouts_FlexibleLayouts_TextPanelWithTwoBkgdImgs {
    imageBoxes {
      image {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              height: 1200
              placeholder: NONE
            )
          }
        }
      }
    }
    sectionHeading
    text
    button {
      url
      target
      title
    }
  }
`

export const ACFTextPanelWithTwoBkgdImgs = ({
  imageBoxes,
  sectionHeading,
  text,
  button
}) => (
  <TextPanelWithTwoBkgdImgs
    imageBoxes={imageBoxes}
    sectionHeading={sectionHeading}
    text={text}
    button={button}
  />
)
