import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col } from "react-bootstrap"

import { Section } from "../../UI/Common"

import TextPanel from "./TextPanel"
import {
  boxWrapperCls,
  bkgdImgCls,
  textPanelPositionCls,
} from "./TextPanelWithTwoBkgdImgs.module.scss"

const TextPanelWithTwoBkgdImgs = ({
  imageBoxes,
  sectionHeading,
  text,
  button
}) => {
  return (
    <Section className="position-relative">
      <>
        <Row>
          {React.Children.toArray(imageBoxes.map((box, i) => {
            let bkgdImgStyles = bkgdImgCls

            return (
              <Col className="px-0" xs={12} lg={6}>
                <div className={boxWrapperCls}>
                  <GatsbyImage
                    className={bkgdImgStyles}
                    style={{ position: "absolute" }}
                    alt={box.image?.altText}
                    image={getImage(box.image?.localFile)}
                  />
                </div>
              </Col>
            )
          }))}
        </Row>
        <div className={textPanelPositionCls}>
          <TextPanel
            sectionHeading={sectionHeading}
            text={text}
            button={button}
          />
        </div>
      </>
    </Section>
  )
}

export default TextPanelWithTwoBkgdImgs
