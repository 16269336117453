// extracted by mini-css-extract-plugin
export var bgGrey100 = "TextPanelWithTwoBkgdImgs-module--bg-grey-100--0cbea";
export var bgGrey150 = "TextPanelWithTwoBkgdImgs-module--bg-grey-150--8fef5";
export var bgGrey200 = "TextPanelWithTwoBkgdImgs-module--bg-grey-200--2fd17";
export var bgGrey300 = "TextPanelWithTwoBkgdImgs-module--bg-grey-300--2899e";
export var bgGrey400 = "TextPanelWithTwoBkgdImgs-module--bg-grey-400--c7c26";
export var bgGrey500 = "TextPanelWithTwoBkgdImgs-module--bg-grey-500--985d6";
export var bgGrey600 = "TextPanelWithTwoBkgdImgs-module--bg-grey-600--d014c";
export var bgGrey700 = "TextPanelWithTwoBkgdImgs-module--bg-grey-700--4ef6c";
export var bgGrey800 = "TextPanelWithTwoBkgdImgs-module--bg-grey-800--f76eb";
export var bgGrey900 = "TextPanelWithTwoBkgdImgs-module--bg-grey-900--f924c";
export var bkgdImgCls = "TextPanelWithTwoBkgdImgs-module--bkgdImgCls--17e7c";
export var boxWrapperCls = "TextPanelWithTwoBkgdImgs-module--boxWrapperCls--74d5e";
export var textGrey100 = "TextPanelWithTwoBkgdImgs-module--text-grey-100--65a26";
export var textGrey150 = "TextPanelWithTwoBkgdImgs-module--text-grey-150--bbb62";
export var textGrey200 = "TextPanelWithTwoBkgdImgs-module--text-grey-200--c2977";
export var textGrey300 = "TextPanelWithTwoBkgdImgs-module--text-grey-300--83b74";
export var textGrey400 = "TextPanelWithTwoBkgdImgs-module--text-grey-400--477c0";
export var textGrey500 = "TextPanelWithTwoBkgdImgs-module--text-grey-500--d4abf";
export var textGrey600 = "TextPanelWithTwoBkgdImgs-module--text-grey-600--2b465";
export var textGrey700 = "TextPanelWithTwoBkgdImgs-module--text-grey-700--61322";
export var textGrey800 = "TextPanelWithTwoBkgdImgs-module--text-grey-800--6d403";
export var textGrey900 = "TextPanelWithTwoBkgdImgs-module--text-grey-900--c0764";
export var textPanelPositionCls = "TextPanelWithTwoBkgdImgs-module--textPanelPositionCls--f1d70";